import { apiUrl, _axios_base_get_list } from "..";
import { newsPrefix } from "./index";
// const PAGESIZE = 100;

export default function getNews(limit, skip = 0, query = {}, accessKey = "") {
  return _axios_base_get_list(
    `${apiUrl}/${newsPrefix}/getNews`,
    limit,
    skip,
    query,
    accessKey
  );
}
