import { isEmpty } from "@antv/util";
import { Spin } from "antd";
import { cloneDeep, map } from "lodash";
import forEach from "lodash.foreach";
import get from "lodash.get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { comingSoonImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getNews from "../../../newApi/news/getNews";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import ScrollLoadWrapper from "../../general/components/ScrollLoadWrapper ";
import NewsDrawerInfo from "../component/NewsDrawerInfo";

const PAGESIZE = 10;
// markup
const NewsListingPage = (props) => {
  // const location = useLocation();
  const { t, languages } = useTranslation();
  const accessKey = get(props.user, "accessKey");

  const [loading, setLoading] = useState(false);
  // const [languageType, setLanguageType] = useState("all");
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [viewNews, setViewNews] = useState(false);

  useEffect(() => {
    getData((page - 1) * PAGESIZE);
  }, [page]);

  // useEffect(() => {
  //   // console.log("change type useEffect");
  //   getData((1 - 1) * PAGESIZE);
  // }, [languageType]);

  function getData(skip) {
    setLoading(true);
    let filteredGroup = {};
    // if (!isNaN(parseInt(languageType))) {
    //   filteredGroup.languageType = parseInt(languageType);
    // }
    filteredGroup.languageType = languages === "en" ? 0 : 1;

    getNews(
      PAGESIZE,
      skip,
      {
        ...filteredGroup,
        sort: {
          sequence: 1,
        },
      },
      accessKey
    )
      .then((res) => {
        // console.log("res", res);
        let data = get(res, "data");
        if (!isEmpty(data)) {
          let temp_data = cloneDeep(dataSource);
          forEach(data, (item, index) => {
            temp_data.push(item);
          });
          setDataSource(temp_data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // const newsTypes = [
  //   {
  //     value: "all",
  //     // text: "All Language",
  //     text: t("allLanguage", sourceKey.newAndVideo),
  //   },
  //   {
  //     value: "0",
  //     // text: "English",
  //     text: t("english", sourceKey.newAndVideo),
  //   },
  //   {
  //     value: "1",
  //     text: t("chinese", sourceKey.newAndVideo),
  //     // text: "Chinese",
  //   },
  // ];
  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack={true}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {/* 最新资讯  */}
                  {/* Latest News */}
                  {t("latestNews", sourceKey.newAndVideo)}
                </div>
              </React.Fragment>
            }
          >
            {/* <div className="grid grid-cols-6 gap-2">
              {map(newsTypes, (item, index) => {
                return (
                  <div
                    className={`col-span-2 p-4 rounded-2xl text-center cursor-pointer font-semibold m-2 text-sm ${
                      languageType === item.value
                        ? "bg-main-color-gradient"
                        : "box-bg-color"
                    } `}
                    style={
                      {
                        // color: `${languageType === item.value ? "" : "grey"}`,
                      }
                    }
                    onClick={() => {
                      // setLanguageType(item.value);
                      if (languageType !== item.value) {
                        setDataSource([]);
                        let page_value = 1;
                        setPage(page_value);
                        setLanguageType(item.value);
                      }
                    }}
                  >
                    {get(item, "text")}
                  </div>
                );
              })}
            </div> */}

            <div className="mx-2 pb-4">
              <ScrollLoadWrapper
                style={{ height: "81vh" }}
                onScrolledBottom={() => {
                  let length = cloneDeep(page);
                  setPage(length + 1);
                }}
              >
                <div className="grid grid-cols-4 gap-2">
                  {isEmpty(dataSource) && loading === false && (
                    <div className="col-span-4 flex justify-center mt-4">
                      <img src={comingSoonImg} style={{ height: "70%" }} />
                    </div>
                  )}
                  {map(dataSource, (item, index) => {
                    let split_content;
                    if (!isEmpty(get(item, "content"))) {
                      split_content = get(item, "content").split("\n");
                    }
                    return (
                      <div
                        className="col-span-4 sm:col-span-2 lightgreybox-bg-color  mt-5 px-2 py-4 mr-2 rounded-t-3xl box-shadow-top"
                        style={{
                          borderRadius: "60px",
                          //   width: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedData(item);
                          setViewNews(true);
                        }}
                        key={index}
                      >
                        <div className="flex justify-center">
                          <span className="font-bold text-lg">
                            {get(item, "title")}
                          </span>
                        </div>

                        <div className="m-4 ">
                          <img
                            src={get(item, "attachFile[0].url")}
                            style={{ width: "100%", height: 180 }}
                            className="img-cover"
                          />
                          <span>
                            {moment(get(item, "createdDate")).format(
                              "d MMMM YYYY"
                            )}
                          </span>
                          <div>
                            {map(split_content, (content, index) => {
                              if (isEmpty(content)) {
                                return <br />;
                              } else {
                                return <div>{content}</div>;
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </ScrollLoadWrapper>
            </div>
          </NavHeader>
          <NewsDrawerInfo
            data={selectedData}
            visible={viewNews}
            onClose={() => {
              setSelectedData({});
              setViewNews(false);
            }}
          />
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsListingPage);
