import { ArrowLeftOutlined } from "@ant-design/icons";
import { isEmpty } from "@antv/util";
import { Drawer, Spin } from "antd";
import { map } from "lodash";
import get from "lodash.get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import Layout from "../../general/components/Layout";

// const PAGESIZE = 10;
// markup
const NewsDrawerInfo = (props) => {
  // const location = useLocation();
  const [visible, setVisible] = useState(false);
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  useEffect(() => {
    setSelectedData(props.data);
  }, [props.data]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
    // setSelectedData({});
  }

  const _renderContent = () => {
    let split_description;
    if (!isEmpty(get(selectedData, "description"))) {
      split_description = get(selectedData, "description").split("\n");
    }
    return (
      <div
        className="col-span-4 bg-white text-black mt-5 px-2 py-4 mr-2 rounded-t-3xl box-shadow-top"
        style={{
          borderRadius: "60px",
          width: "100%",
        }}
      >
        <div className="m-4 ">
          <span>
            {moment(get(selectedData, "createdDate")).format("d MMMM YYYY")}
          </span>
          <div>
            {map(split_description, (content, index) => {
              if (isEmpty(content)) {
                return <br />;
              } else {
                return <div>{content}</div>;
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Drawer
        visible={visible}
        width="100%"
        className="body-no-padding"
        closable={false}
        onClose={() => {
          close();
        }}
      >
        <Layout>
          <Spin spinning={loading}>
            <div className="theme-bg-color" style={{ minHeight: "95vh" }}>
              <div className="flex justify-between items-center px-4 pt-5 mb-4">
                <span
                  className="flex items-center cursor-pointer"
                  onClick={(e) => {
                    close();
                  }}
                >
                  <ArrowLeftOutlined
                    className="mr-2"
                    style={{ fontSize: 20 }}
                  />
                </span>
                <span className="inline-block text-xl text-right  font-bold">
                  {get(selectedData, "title")}
                </span>
                <span className="inline-block"> </span>
              </div>

              <div className="flex justify-center">
                <img
                  src={get(selectedData, "attachFile[0].url")}
                  style={{ width: "40%", height: "40%" }}
                  className="img-cover"
                />
              </div>

              <div className="m-2 pb-10">
                <div className="grid grid-cols-4 gap-2 ">
                  {_renderContent()}
                </div>
              </div>
            </div>
          </Spin>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsDrawerInfo);
