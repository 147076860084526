import * as React from "react";
import SharedHeader from "../components/general/components/SharedHeader";
import NewsListingPage from "../components/news/pages/NewsListingPage";

// markup
const Profile = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <NewsListingPage />
    </React.Fragment>
  );
};

export default Profile;
